import { ChevronsRight } from "lucide-react";
import { useRef } from "react";
import { useFetch } from "../hooks/useFetch";
import ReviewModal from "./ReviewModal";
import Testimonial from "./Testimonial/Testimonial";
import TestimonialSkeleton from "../Components/Skeletons/TestimonialSkeleton";
import Animate from "./Animations/Animate";

import "./Testimonials.css";

const Testimonials = () => {
  const { isLoading, data, error } = useFetch("/review");
  const containerRef = useRef(null);

  const handleNext = () => {
    const container = containerRef.current;
    container.scrollLeft += 450;
  };

  const handlePrevious = () => {
    const container = containerRef.current;
    container.scrollLeft -= 450;
  };

  return (
    <div id="testimonials">
      <div className="testimonials-container">
        <Animate delay={0.2} className="testimonials-header">
          <h3>Testimonials</h3>
          <h2>Our happy clients say about us</h2>
        </Animate>
        <Animate delay={0.3} className="testimonials-wrapper">
          <div className="testimonials-background"></div>
          <div className="testimonials" ref={containerRef}>
            {isLoading || error ? (
              <>
                <TestimonialSkeleton />
                <TestimonialSkeleton />
                <TestimonialSkeleton />
              </>
            ) : (
              data.map((review) => {
                return data && data.length > 0 ? (
                  <>
                    <Testimonial key={review.id} data={review} />
                  </>
                ) : (
                  <p>No testimonials found.</p>
                );
              })
            )}
          </div>
        </Animate>
        <div className="testimonials-buttons">
          <div>
            <ChevronsRight
              onClick={handlePrevious}
              className="back-btn"
              size={35}
            />
          </div>
          <div>
            <ChevronsRight
              onClick={handleNext}
              className="forward-btn"
              size={35}
            />
          </div>
        </div>
        <div className="testimonials-leave-review">
          <p>
            <span className="thin">
              Want to share your experience with us?{" "}
            </span>
            <ReviewModal />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
