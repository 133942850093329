import React from "react";
import "./FaqModal.css";
import ReactDom from "react-dom";
import closepage from "./delete.png";
import { motion } from "framer-motion";
import ModalContent from "./ModalContent";

const ModelStyles = {
  position: "fixed",
  top: "10%",
  left: "5%",
  borderRadius: "35px",
  overflow: "auto",
  width: "90%",
  margin: "auto",
  height: "100%",
  transoform: "translate(50%, 50%)",
  backgroundColor: "#FFF",
  padding: "50px",
  zIndex: 500,
};
const OverlayStyle = {
  position: "fixed",
  top: "0",
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: "rgba(0,0,0,0.7)",
  padding: "50px",
  zIndex: 400,
};

const FaqModal = ({ open, onClose }) => {
  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <>
      <div onClick={onClose} style={OverlayStyle} />
      <motion.div
        initial={{ opacity: 0, y: "10%" }}
        whileInView={{ opacity: 1, y: "0%" }}
        transition={{ delay: 0, duration: 0.3, ease: "linear" }}
        viewport={{ once: true }}
        style={ModelStyles}
      >
        <div className="Modal-head">
          <h2>Answers to our most asked question!</h2>
          <button onClick={onClose}>
            <img className="close-icon" src={closepage} alt="close" />
          </button>
        </div>
        <ModalContent />
      </motion.div>
    </>,
    document.getElementById("portal")
  );
};

export default FaqModal;
