import React, { useEffect, useState } from "react";
import "./CompanyStrip.css";
import inside from "../Assets/insider.svg";
import morgan from "../Assets/morgan.svg";
import rubrik from "../Assets/rubrik.svg";
import thought from "../Assets/logo4.svg";
import virta from "../Assets/logo5.svg";

const CompanyStrip = () => {
  const [backgroundPosition, setBackgroundPosition] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to execute when scrolling
  const executeOnScroll = () => {
    // Your logic here
    setBackgroundPosition((prevPosition) => prevPosition - 2);
  };

  // Execute the function when scrollY state changes
  useEffect(() => {
    executeOnScroll();
  }, [scrollY]);

  return (
    <div
      className="strip"
      style={{ backgroundPosition: `${backgroundPosition}px 0` }}
    ></div>
  );
};

export default CompanyStrip;
