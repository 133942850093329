import { motion } from "framer-motion";

const AnimateHeading = ({
  className,
  delay = 0,
  duration = 0.3,
  children,
  initialY = "10%",
  InViewY = "0%",
  initialOpacity = 0,
  InViewOpacity = 1,
  once = true,
}) => {
  return (
    <motion.h1
      className={className}
      initial={{ opacity: initialOpacity, y: initialY }}
      whileInView={{ opacity: InViewOpacity, y: InViewY }}
      transition={{ delay: delay, duration: duration, ease: "linear" }}
      viewport={{ once: once }}
    >
      {children}
    </motion.h1>
  );
};

export default AnimateHeading;
