import React, { useState } from "react";
import Button from "./Button";
import { Calendar, Input, Modal, Select } from "antd";
import { Button as ButtonForm } from "antd";
import "./BookCallModal.css";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import { useForm } from "../hooks/useForm";
import { serviceOptions } from "../data";
import SubmittedCheck from "./SubmittedCheck";
const { TextArea } = Input;

const BookCallModal = ({ btn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm("/appointment", {
    defaultValues: {
      name: "",
      service: "",
      message: "",
      date: null,
      email: "",
      time: null,
    },
  });

  const {
    handleOnSubmit,
    formSucess,
    isLoading,
    isSubmitted,
    setFormDate,
    setFormOther,
    setFormInput,
    setFormTime,
    formData,
    error,
    reset,
  } = form;

  const modal = () => {
    return {
      show: () => setIsModalOpen(true),
      ok: () => setIsModalOpen(false),
      cancel: () => setIsModalOpen(false),
    };
  };

  const onPanelChange = (value, mode) => {
    const newDate = new Date(value.valueOf());
    console.log("date: " + newDate.toLocaleString());
  };

  const handleCalendarDates = (e) => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7);

    const disabledWeek = nextWeek.valueOf();
    const date = e.valueOf();
    return date && date < disabledWeek;
  };

  const defaultDate = dayjs().add(8, "day");
  const checkSubmitted = isSubmitted ? "Scheduled" : "Schedule";

  return (
    <>
      {/*Modal Trigger */}
      <Button onClick={modal().show} btn={btn}>
        Book a Call
      </Button>

      {/*Modal Content*/}
      <Modal
        open={isModalOpen}
        onOk={modal().ok}
        onCancel={modal().cancel}
        width={1000}
        title={formSucess ? "" : "Schedule a Call"}
        footer={() => {}}
      >
        {formSucess ? (
          <>
            <div className="book-form-success">
              <h3>Booked Sucessfully</h3>
              <p>Thank you, you have booked an appointment with Wireflow</p>
              <SubmittedCheck />
            </div>
          </>
        ) : (
          <form onSubmit={handleOnSubmit} className="book-form-flex">
            <div className="book-modal">
              <div className="book-calendar">
                <Calendar
                  fullscreen={false}
                  onPanelChange={onPanelChange}
                  required
                  onChange={(e) => setFormDate(e, "date")}
                  disabledDate={handleCalendarDates}
                  defaultValue={defaultDate}
                />
                <div>
                  <lable>Choose Time</lable>
                  <TimePicker
                    required
                    use12Hours
                    className="formInput"
                    format="h:mm a"
                    onChange={(e) => setFormTime(e, "time")}
                  />
                </div>
              </div>
              <div className="book-form">
                <label>Name</label>
                <Input
                  value={formData.name}
                  required
                  onChange={setFormInput}
                  type="text"
                  name="name"
                  placeholder="ex. John Doe"
                  className="formInput"
                />

                <label>Email</label>
                <Input
                  value={formData.email}
                  required
                  onChange={setFormInput}
                  type="text"
                  name="email"
                  placeholder="ex. example@gmail.com"
                  className="formInput"
                />

                <label>Service</label>
                <Select
                  value={formData.service}
                  style={{ width: "100%" }}
                  className="service-selector"
                  placeholder="Select Service"
                  options={serviceOptions}
                  name="service"
                  onSelect={(e) => setFormOther(e, "service")}
                />

                <label>Message</label>
                <TextArea
                  value={formData.message}
                  name="message"
                  cols="30"
                  rows="5"
                  required
                  onChange={setFormInput}
                  type="text"
                  className="modal-textarea"
                  placeholder="ex. I'm want to discuss..."
                />
              </div>

              {error ? (
                <p className="form-error">
                  Failed to schedule appointment!{" "}
                  <span className="form-reset" onClick={reset}>
                    Reset
                  </span>
                </p>
              ) : null}
            </div>
            <div className="book-buttons">
              <ButtonForm
                style={{ background: "black", color: "white", padding: "10px" }}
                htmlType="submit"
                type="submit"
                className="book-btn"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : checkSubmitted}
              </ButtonForm>
              <ButtonForm
                ghost
                style={{
                  color: "red",
                  border: "0px solid red",
                  padding: "10px",
                }}
                className="book-btn"
                onClick={modal().cancel}
              >
                Cancel
              </ButtonForm>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};

export default BookCallModal;
