import React from "react";

const Button = ({ children, btn, onClick }) => {
  const btnType = ["ContactUsBtn", "BookCallLink"];

  let btnStyle = "";

  return (
    <>
      <button
        onClick={onClick}
        className={`contactBtn ${btn ? (btnStyle = btnType[0]) : (btnStyle = btnType[1])}`}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
