import React from "react";
import "./QuestionsModal.css";

const QuestionsModal = ({ question, answer }) => {
  return (
    <div className="questions">
      <h3>{question}</h3>
      <p>{answer}</p>
    </div>
  );
};

export default QuestionsModal;
