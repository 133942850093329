import { ValuableAddons } from "../data";
import Addon from "./Addon/Addon";
import "./Addons.css";
import { motion } from "framer-motion";

const Addons = () => {
  return (
    <div className="addons-container container" id="addon">
      <div className="addons-header">
        <h1 className="nowrap">
          <span className="thin">VALUABLE </span>
          ADD-ONS
        </h1>
        <p>
          Unlock your digital potential with our range of empowering services,
          designed to enhance your online presence and drive lasting success.
        </p>
      </div>
      <div className="addons-wrapper">
        <div className="addons">
          {ValuableAddons.map((addon, idx) => (
            <Addon key={idx} addon={addon} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Addons;
