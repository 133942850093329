import React from "react";
import { Link } from "react-scroll";
import Animate from "./Animations/Animate";
import Button from "./Button";
import "./ResultsDelivered.css";
import PhoneSwiper from "./Swiper";

const ResultsDelivered = () => {
  return (
    <div className="results" id="results">
      <div className="results-heading">
        <Animate className="results-heading-top">
          <p>TURNING IDEAS INTO STARTUPS</p>
          <h1>
            <span className="thin">RESULTS</span> DELIVERED
          </h1>
        </Animate>

        <Animate className="results-cta" delay={0.2}>
          <Link
            to="/services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Button>
              <Link to="services-container" smooth duration={500}>
                Our Services
              </Link>
            </Button>
          </Link>
          <p>100% CUSTOMER SATISFACTION</p>
        </Animate>

        <Animate delay={0.2} initialY="20%">
          <PhoneSwiper />
        </Animate>
      </div>
    </div>
  );
};

export default ResultsDelivered;
