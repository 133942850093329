import React from "react";
import "./Footer.css";
import { footerSocials, links } from "../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookCallModal from "./BookCallModal";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="footer-content">
            <h2 className="footer-title">BUILD INTELLIGENT APPS</h2>
            <p className="footer-para">
              Work with the leading WireFlow
              <br />
              Software Development Company
            </p>
            <hr className="footer-border" />
            <div className="footer-content-bottom">
              <Link to="/" spy={true} smooth={true} offset={-70} duration={500}>
                <h3 className="logo">
                  WIRE<span className="logo-touch">FLOW</span>
                </h3>
              </Link>

              <span className="copyright">Copyright © 2023 WireFlow LLC.</span>
              <ul className="footer-social-links">
                {footerSocials.map((footerSocial, index) => (
                  <li key={index} className="footer-social-link">
                    <a
                      href={footerSocial.link}
                      className="footer-social-link-anchor"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={footerSocial.icon} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <hr className="footer-border" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
