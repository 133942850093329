import { useState } from "react";
import "./FixedNavbar.css";
import logo from "../Assets/wireflow-logo.png";
import { useEffect } from "react";
import { Turn as Hamburger } from "hamburger-react";
import { useWindowSize } from "../utils/useWindowSize";
import { Link } from "react-scroll";

const NavButton = ({ text, onClick, href }) => {
  return (
    <>
      <Link to={href} className="fixed-link" duration={500} smooth>
        <button className="fixed-button" onClick={onClick}>
          {text}
        </button>
      </Link>
    </>
  );
};

const NavCTA = ({ text, onClick, href }) => {
  return (
    <>
      <Link to={href} className="fixed-link" duration={500} smooth>
        <button className="fixed-CTA" onClick={onClick}>
          {text}
        </button>
      </Link>
    </>
  );
};

const FixedNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const Buttons = [
    { name: "RESULTS", href: "results" },
    { name: "WORK", href: "Delivery-container" },
    { name: "Services", href: "services" },
    { name: "ADDONS", href: "addons-container" },
    { name: "FAQ", href: "faq-container" },
    { name: "Testimonials", href: "testimonials" },
  ];

  useWindowSize(size, setSize);

  useEffect(() => {
    if (size.width > 899) setIsOpen(false);
  }, [size.width]);

  return (
    <>
      <div
        className={`fixed-navbar ${!isOpen ? "hide-nav" : ""} ${
          !isOpen && size.width > 899 ? "show-nav" : ""
        }`}
      >
        <div className="fixed-content">
          {!isOpen ? (
            <Link
              to="hero"
              duration={500}
              smooth
              onClick={() => setIsOpen(false)}
            >
              <img src={logo} alt="logo" className="fixed-logo" />
            </Link>
          ) : null}
          {Buttons.map((text, idx) => (
            <NavButton
              text={text.name}
              href={text.href}
              onClick={() => setIsOpen(false)}
            />
          ))}
          <NavCTA
            text={"CONTACT"}
            onClick={() => setIsOpen(false)}
            href={`${isOpen ? "form-mobile" : "form"}`}
          />
        </div>
      </div>
      <div className={`${!isOpen ? "fixed-burger" : "fixed-burger-open"}`}>
        <Hamburger
          size={30}
          color="white"
          toggle={setIsOpen}
          toggled={isOpen}
        />
      </div>
    </>
  );
};

export default FixedNavbar;
