import "./Testimonial.css";
import profile from "../../Assets/testimonial-img.png";
import { renderStars } from "../../utils/renderStars";
import { CgProfile } from "react-icons/cg";

const Testimonial = ({ data: { stars, review, img, name, title } }) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-stars">{renderStars(stars)}</div>
      <div className="testimonial-review">{review}</div>
      <div className="testimonial-profile">
        <div className="testimonial-img">
          <CgProfile size={40} />
        </div>
        <div className="testimonial-info">
          <h3>{name}</h3>
          <h4>{title}</h4>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
