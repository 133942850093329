import Animate from "../Animations/Animate";
import "./Question.css";
import { motion } from "framer-motion";

const Question = ({ question: { question, answer, delay } }) => {
  return (
    <Animate delay={delay} duration={0.3} className="question-card">
      <div className="question">
        <h3>{question}</h3>
      </div>
      <div className="answer">
        <p>{answer}</p>
      </div>
    </Animate>
  );
};

export default Question;
