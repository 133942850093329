import "./WorkflowCard.css";
import { useEffect, useState } from "react";

const WorkflowCard = ({
  title,
  description,
  hiddentitle,
  hiddendescription,
  background,
  gradient,
  column,
  left,
  textgap,
  ...props
}) => {
  const [columnType, setColumnType] = useState(column ? "column" : "row");
  const [alignment, setAlignment] = useState("center");
  const [contentTextGap, setContentTextGap] = useState(`${textgap}rem`);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleAlignment = () => {
    if (windowWidth > 1150) {
      setColumnType(column ? "column" : "row");
      setAlignment("center");
      setContentTextGap(`${textgap}rem`);
    } else if (windowWidth <= 1150) {
      setColumnType("column");
      setAlignment("center");
      setContentTextGap("1rem");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  useEffect(() => {
    handleAlignment();
  }, [windowWidth, column]);
  return (
    <div
      className="workflow-card"
      style={{
        background: `var(${gradient})`,
      }}
    >
      <div
        className="workflow-show-content"
        style={{
          flexDirection: columnType,
          justifyContent: alignment,
          gap: contentTextGap,
        }}
      >
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="workflow-hidden-content">
        <h2>{hiddentitle}</h2>
        <p>{hiddendescription}</p>
      </div>
      <div className="workflow-card-circle"></div>
      <div className="workflow-card-img">
        <img src={background} alt="img" />
      </div>
    </div>
  );
};

export default WorkflowCard;
