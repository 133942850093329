import React from "react";
import { deliveries } from "../data";
import "./Delivering.css";
import WorkflowCard from "./Workflow/WorkflowCard";

const Delivering = () => {
  return (
    <div className="Delivery-container">
      <div className="Delivery-text-container">
        <p className="Delivery-title-p-1">HOW WE WORK</p>
        <h1 className="Delivery-title-h1-1">DELIVERING ON TIME</h1>
        <h1 className="Delivery-title-h1-2">EVERY TIME</h1>
        <p className="Delivery-title-p-2">
          Discover our seamless project workflow
        </p>
      </div>
      <div className="workflow-grid">
        {deliveries.map((info, index) => (
          <WorkflowCard
            key={index}
            title={info.title}
            description={info.description}
            hiddentitle={info.hiddentitle}
            hiddendescription={info.hiddendescription}
            background={info.background}
            gradient={info.gradient}
            column={info.column}
            left={info.left}
            textgap={info.textgap}
          />
        ))}
      </div>
    </div>
  );
};

export default Delivering;
