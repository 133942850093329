import React, { useState, useEffect } from "react";
import "./App.css";
import "./styles/globals.css";
import Homepage from "./Pages/Home";
import arrow from "./Assets/arrow.png";
import FixedNavbar from "./Components/FixedNavbar";
import { Spiral as Hamburger } from "hamburger-react";

function App() {
  const [showUpIcon, setShowUpIcon] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowUpIcon(true);
      } else {
        setShowUpIcon(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div className="App">
      {/* {showUpIcon && (
        <>
          <div>
            </div>
        </>
      )} */}
      <header>
        <FixedNavbar />
      </header>

      <body>
        <Homepage />
      </body>
    </div>
  );
}

export default App;
