import "./TestimonialSkeleton.css";

const TestimonialSkeleton = () => {
  return (
    <div className="testimonial-card-skeleton">
      <div className="testimonial-stars-skeleton">
        <h3></h3>
        <h3></h3>
        <h3></h3>
        <h3></h3>
        <h3></h3>
      </div>
      <div className="testimonial-review-skeleton">
        <h3></h3>
        <h3></h3>
        <h3></h3>
        <h3></h3>
      </div>
      <div className="testimonial-profile-skeleton">
        <div className="testimonial-img-skeleton">
          <span />
        </div>
        <div className="testimonial-info-skeleton">
          <h3></h3>
          <h4></h4>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSkeleton;
