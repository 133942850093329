import "./ReviewModal.css";
import React, { useState } from "react";
import { Input, Modal, Rate } from "antd";
import { Button as ButtonForm } from "antd";
import "./BookCallModal.css";
import { useForm } from "../hooks/useForm";
import SubmittedCheck from "./SubmittedCheck";
const { TextArea } = Input;

const ReviewModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm("/review", {
    defaultValues: {
      name: "",
      title: "",
      email: "",
      company: "",
      stars: 5,
      review: "",
    },
  });

  const {
    setFormInput,
    isLoading,
    isSubmitted,
    formData,
    formSucess,
    handleOnSubmit,
    setFormOther,
    reset,
    error,
  } = form;

  const modal = () => {
    return {
      show: () => setIsModalOpen(true),
      ok: () => setIsModalOpen(false),
      cancel: () => setIsModalOpen(false),
    };
  };

  return (
    <>
      {/*Modal Trigger */}
      <span className="leave-review" onClick={modal().show}>
        Leave us a review
      </span>

      {/*Modal Content*/}
      <Modal
        open={isModalOpen}
        onOk={modal().ok}
        onCancel={modal().cancel}
        title={formSucess ? "" : <h3>Leave a review</h3>}
        footer={() => {}}
        bodyStyle={{ width: "100%" }}
      >
        {formSucess ? (
          <div className="sucess-message">
            <p>We Recieved Your Review</p>
            <h2>Thank you for your feedback</h2>
            <SubmittedCheck />
          </div>
        ) : (
          <form onSubmit={handleOnSubmit} className="book-form-flex">
            <div className="book-form">
              <div>
                <label>Name</label>
                <Input
                  value={formData.name}
                  required
                  onChange={setFormInput}
                  type="text"
                  name="name"
                  placeholder="ex. John Doe"
                  className="formInput"
                />
              </div>
              <div>
                <label>Title</label>
                <Input
                  value={formData.title}
                  required
                  onChange={setFormInput}
                  type="text"
                  name="title"
                  placeholder="ex. Cheif Executive"
                  className="formInput"
                />
              </div>
              <div>
                <label>Email</label>
                <Input
                  value={formData.email}
                  required
                  onChange={setFormInput}
                  type="email"
                  name="email"
                  placeholder="ex. name@email.com"
                  className="formInput"
                />
              </div>
              <div>
                <label>Company</label>
                <Input
                  value={formData.company}
                  required
                  onChange={setFormInput}
                  type="text"
                  name="company"
                  placeholder="ex. Facebook, Google"
                  className="formInput"
                />
              </div>
              <div>
                <label>Stars</label>
                <Rate
                  value={formData.stars}
                  required
                  onChange={(e) => setFormOther(e, "stars")}
                  name="stars"
                  className="formInput"
                />
              </div>
              <div>
                <label>Review</label>
                <TextArea
                  value={formData.review}
                  name="review"
                  cols="30"
                  rows="6"
                  required
                  onChange={setFormInput}
                  type="text"
                  className="modal-textarea"
                  placeholder="ex. working with Wireflow has been..."
                />
                {error ? (
                  <p className="form-error">
                    Failed to schedule appointment!{" "}
                    <span className="form-reset" onClick={reset}>
                      Restart
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
            <div className="book-buttons">
              <ButtonForm
                style={{ background: "black", color: "white", padding: "10px" }}
                htmlType="submit"
                type="submit"
                className="book-btn"
                disabled={isLoading}
              >
                {isLoading ? "Posting..." : "Post"}
              </ButtonForm>
              <ButtonForm
                ghost
                style={{
                  color: "red",
                  border: "0px solid red",
                  padding: "10px",
                }}
                className="book-btn"
                onClick={() => {
                  modal().cancel();
                  reset();
                }}
              >
                Cancel
              </ButtonForm>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};

export default ReviewModal;
