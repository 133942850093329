import React from "react";
import Button from "../Button";
import "./Addon.css";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import Animate from "../Animations/Animate";

const Addon = ({ addon: { service, features, delay } }) => {
  return (
    <Animate delay={delay} duration={0.3} className="addon-card">
      <div className="addon-header">
        <h1>{service}</h1>
      </div>
      <ul className="addon-features">
        {features.map((feature, index) => (
          <div key={index}>{feature}</div>
        ))}
      </ul>
      <div className="addon-cta">
        <Link to="form" spy={true} smooth={true} duration={500}>
          <Button>Contact Us</Button>
        </Link>
      </div>
    </Animate>
  );
};

export default Addon;
