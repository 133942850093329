import React, { useEffect, useState } from "react";
import { socials } from "../data";
import { Button, Input, Select } from "antd";
import "./Form.css";
import SubmittedCheck from "./SubmittedCheck";
import { useForm } from "../hooks/useForm";
import { serviceOptions, addonsOptions } from "../data";
const { TextArea } = Input;

const Form = () => {
  const form = useForm("/message", {
    defaultValues: {
      name: "",
      email: "",
      service: "",
      addon: "",
      message: "",
    },
  });

  const {
    isLoading,
    isSubmitted,
    formData,
    handleOnSubmit,
    setFormOther,
    setFormInput,
  } = form;

  return (
    <section id="form">
      <div className="container " id="/contact">
        <div className="row">
          <div className="form-content">
            <div className="form-left">
              <h2 className="form-left-title">
                LET'S <span className="bold">TALK</span>
              </h2>
              <p className="form-left-para">
                Have some big idea or brand to develop and need help? Then reach
                out we'd love to hear about your project and provide help
              </p>
              <h3 className="form-left-subtitle">EMAIL</h3>
              <a
                href="mailto:wireflowllc@gmail.com"
                className="form-left-email"
              >
                wireflowllc@gmail.com
              </a>
              <h3 className="form-left-subtitle form-left-subtitle-special">
                SOCIALS
              </h3>
              <ul className="form-left-links">
                {socials.map((social, index) => (
                  <li key={index} className="form-left-link">
                    <a
                      href={social.link}
                      className="form-left-link-anchor"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {social.title}
                    </a>
                  </li>
                ))}
              </ul>
              <span className="form-mobile" />
            </div>
            {isSubmitted ? (
              <SubmittedCheck />
            ) : (
              <div className="form-right">
                <form onSubmit={handleOnSubmit}>
                  <label>Name</label>
                  <Input
                    maxLength={50}
                    type="text"
                    placeholder="ex. John Doe"
                    name="name"
                    required
                    className="formInput"
                    onChange={setFormInput}
                    value={formData.name}
                  />

                  <label>Email</label>
                  <Input
                    maxLength={100}
                    type="email"
                    name="email"
                    placeholder="ex. me@johndoe.com"
                    required
                    onChange={setFormInput}
                    className="formInput"
                    value={formData.email}
                  />

                  <label htmlFor="">What service are you interested in?</label>
                  <Select
                    style={{ width: "100%" }}
                    className="service-selector"
                    placeholder="Select Service"
                    options={serviceOptions}
                    name="service"
                    onSelect={(e) => setFormOther(e, "service")}
                    value={formData.service}
                  />

                  <label htmlFor="">Choose your app addons!</label>
                  <Select
                    style={{ width: "100%" }}
                    className="service-selector"
                    placeholder="Select Add-On"
                    name="addon"
                    options={addonsOptions}
                    onSelect={(e) => setFormOther(e, "addon")}
                    value={formData.addon}
                  />

                  <label htmlFor="">Your message to us:</label>
                  <TextArea
                    maxLength={1000}
                    cols="30"
                    rows="10"
                    name="message"
                    type="text"
                    onChange={setFormInput}
                    value={formData.message}
                  />

                  <Button htmlType="submit" type="submit" disabled={isLoading}>
                    {isLoading ? "Submitting..." : "Submit"}
                  </Button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
