import React from "react";
import "./Hero.css";
import iphoneDemo from "../Assets/iphoneDemo.png";
import Button from "./Button";
import Navbar from "./Navbar";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import AnimateHeading from "./Animations/AnimateHeading";
import AnimateSpan from "./Animations/AnimateSpan";

const Hero = () => {
  return (
    <div className="hero" id="/">
      <Navbar />
      <div className="FullHeroContent">
        <div className="HeroTextContent">
          <AnimateHeading delay={0.4} duration={0.3} className="HeroTitleh1">
            <span style={{ fontWeight: 100 }}>ELEVATE YOUR BRAND,</span>
            <br></br>
            EMBRACE TECHNOLOGY
          </AnimateHeading>
          <p className="HeroHeadingP">
            Unleash the power of innovation and drive your business to new
            heights with our cutting-edge solutions and expert guidance.
            <span className="hideText">
              &nbsp;Join us on a transformative journey as we help you harness
              the potential of technology and achieve remarkable success in the
              digital landscape.
            </span>
          </p>
          <div className="heroBtns">
            <Link
              to="/results"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <AnimateSpan initialY="-20%" delay={0.5} duration={0.5}>
                <Button>Get Started</Button>
              </AnimateSpan>
            </Link>
          </div>
        </div>

        <div className="heroWords">
          <div className="HeroBigWord">
            <div className="BigWordWire">
              <h1>WIRE</h1>
            </div>
            <div className="heroPhone">
              <img alt="HeroPhone" src={iphoneDemo} />
            </div>
            <div className="BigWordFlow">
              <h1>FLOW</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
