import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Projects Imports
import meetly from "../Assets/meetly.png";
import designer from "../Assets/designer.png";
import dog from "../Assets/dog.png";
import quiz from "../Assets/quiz.png";
import coindash from "../Assets/coindash.png";

const PhoneSwiper = () => {
  const projects = [meetly, quiz, designer, dog, coindash];

  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      initialSlide={2.5}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 0,
        stretch: 15,
        depth: 230,
        modifier: 1.8,
      }}
      modules={[EffectCoverflow, Navigation, Autoplay]}
      style={{ width: "fit-content" }}
    >
      {projects.map((p, i) => (
        <SwiperSlide style={{ width: "fit-content" }} key={i}>
          <img src={p} alt="project" className="swiper-project" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PhoneSwiper;
