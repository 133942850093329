import "./FAQ.css";
import Question from "./Question/Question";
import FaqModal from "../Components/modal/FaqModal";
import { useState } from "react";
import Animate from "./Animations/Animate";

const FAQ = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const FAQ = [
    {
      question: "What is the process for web development projects?",
      answer:
        "Our web development process involves gathering requirements, designing the user interface, coding, testing, and deploying the website, ensuring a seamless and efficient development experience for our clients.",
      delay: 0.2,
    },
    {
      question: "How does SEO optimization help improve website visibility?",
      answer:
        "SEO optimization enhances your website's visibility by implementing various strategies such as keyword research, on-page optimization, link building, and content optimization, ensuring higher search engine rankings and increased organic traffic.",
      delay: 0.4,
    },
  ];
  const handleClose = () => {
    setModalOpen(false);
    document.documentElement.classList.remove("no-scroll");
  };
  const handleopen = () => {
    document.documentElement.classList.add("no-scroll");
    setModalOpen(true);
  };
  return (
    <div>
      <div className="faq-container">
        <Animate className="faq-header" delay={0.2}>
          <h4>FAQ</h4>
          <h2>
            Discover the most <br /> common questions
          </h2>
          <p>
            Discover answers to frequently asked questions about our services,
            addressing common inquiries and providing helpful insights.
          </p>
        </Animate>
        <div className="faq-questions">
          {FAQ.map((question, index) => (
            <Question key={index} question={question} />
          ))}
          <Animate initialY="20%" delay={0.6} className="faq-more">
            <a onClick={handleopen}>View all common questions</a>
          </Animate>
        </div>
        <FaqModal onClose={handleClose} open={modalOpen} />
      </div>
    </div>
  );
};

export default FAQ;
