import React from "react";
import "./Navbar.css";
import BookCallModal from "./BookCallModal";
import Animate from "./Animations/Animate";

const Navbar = () => {
  return (
    <Animate className="Navbar" delay={0.2} duration={0.7}>
      <div className="logo">
        <h1 className="logo">
          WIRE<span className="logo-touch">FLOW</span>
        </h1>
      </div>
      <div className="contactInteractions">
        <BookCallModal />
      </div>
    </Animate>
  );
};

export default Navbar;
