import React, { useState } from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import "./ModalContent.css";
import QuestionsModal from "./QuestionsModal";
import { FAQModal } from "../../data";

const ModalContent = () => {
  return (
    <>
      <div className="FAQ-Content">
        {FAQModal.map((data, index) => {
          return (
            <>
              <QuestionsModal
                key={index}
                question={data.question}
                answer={data.answer}
              />
            </>
          );
        })}
      </div>
    </>
  );
};

export default ModalContent;
