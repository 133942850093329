import { motion } from "framer-motion";

const Animate = ({
  className,
  delay = 0,
  duration = 0.3,
  children,
  initialY = "10%",
  InViewY = "0%",
  initialX = "0%",
  InViewX = "0%",
  initialOpacity = 0,
  InViewOpacity = 1,
  once = true,
}) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: initialOpacity, y: initialY, x: initialX }}
      whileInView={{ opacity: InViewOpacity, y: InViewY, x: InViewX }}
      transition={{ delay: delay, duration: duration, ease: "linear" }}
      viewport={{ once: once }}
    >
      {children}
    </motion.div>
  );
};

export default Animate;
