import { useEffect } from "react";

export function useWindowSize(size, setSize) {
  useEffect(() => {
    function updateSize() {
      setSize({
        ...size,
        width: window.innerWidth,
        height: window.innerWidth,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}
