import vision from "./Assets/vision.png";
import handshake from "./Assets/handshake.png";

// Addon Section
export const ValuableAddons = [
  {
    service: [
      <span className="thin">
        SEO <br />
      </span>,
      "OPTIMIZATION",
    ],
    features: [
      <li>Boost Online Visibility</li>,
      <li>Monthly Updates</li>,
      <li>Premium Support</li>,
    ],
    price: "Contact for pricing",
    delay: 0,
  },
  {
    service: [
      "MAINTENANCE ",
      <span className="thin">
        <br />&
      </span>,
      " SUPPORT",
    ],
    features: [
      <li>Smooth Website Operation</li>,
      <li>Monthly Updates</li>,
      <li>Premium Support</li>,
    ],
    price: "Contact for pricing",
    delay: 0.1,
  },
  {
    service: [
      <span className="thin">
        APP <br />
      </span>,
      <span className="nowrap">UI/UX DESIGN</span>,
    ],
    features: [
      <li>Stunning App & Website Design</li>,
      <li>Monthly Updates</li>,
      <li>Premium Support</li>,
    ],
    price: "Contact for pricing",
    delay: 0.2,
  },
];

// Workflow Section
export const deliveries = [
  {
    title: (
      <>
        UNDERSTANDING <br /> <span className="thin">YOUR VISION</span>
      </>
    ),
    description:
      "Uncover your project's goals and target audience through in-depth discussions and market research.",
    hiddentitle: "DISCOVERY PHASE",
    hiddendescription:
      "Our team engages in detailed discussions to grasp your vision and project requirements. Through in-depth research, we identify your goals and challenges. This sets a strong foundation for seamless execution.",
    background: vision,
    gradient: "--gradient-purple-blue",
    column: false,
    textgap: "3",
  },
  {
    title: (
      <>
        CRAFTING INTUITIVE <br /> <span className="thin">DESIGNS</span>
      </>
    ),
    description:
      "Create captivating, user-friendly designs that reflect your brand identity and enhance user engagement.",
    hiddentitle: <span className="nowrap">DESIGN & PLANNING</span>,
    hiddendescription:
      "In the Design & Planning Phase, our creative designers ensure intuitive interfaces, iterate through wireframes, and plan with a realistic timeline.",
    background: "",
    gradient: "--gradient-orange-pink",
    column: true,
    left: true,
    textgap: "1",
  },
  {
    title: (
      <>
        BRINGING IDEAS
        <br /> <span className="thin">TO LIFE</span>
      </>
    ),
    description:
      "Transform approved designs into a robust, scalable website or application with rigorous testing and agile development.",
    hiddentitle: "DEVELOPMENT & IMPLEMENTATION",
    hiddendescription:
      "During Development, our skilled team codes approved designs into functional apps. Rigorous testing and agile practices guarantee a flawless product.",
    background: "",
    gradient: " --gradient-black-green",
    column: true,
    left: true,
    textgap: "1",
  },
  {
    title: (
      <>
        GOING LIVE <br /> <span className="thin">& BEYOND</span>
      </>
    ),
    description:
      "Seamlessly deploy your project, and provide ongoing support to ensure its success long after launch.",
    hiddentitle: <span className="nowrap">LAUNCH & SUPPORT</span>,
    hiddendescription:
      "In the Launch & Support Phase, we deploy your project smoothly. Ongoing support and monitoring ensure peak performance. We assist with updates and future features for sustained growth.",
    background: handshake,
    gradient: "--gradient-yellow-orange",
    column: false,
    textgap: "3",
  },
];

// Footer
export const socials = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/wireflowtech/",
  },
  {
    title: "Twitter",
    link: "https://twitter.com/wireflowtech",
  },
  {
    title: "Facebook",
    link: "https://www.linkedin.com/in/h4temsoliman/",
  },
  {
    title: "Linkedin",
    link: "https://www.linkedin.com/in/fam-a-louiz-51a63b266/",
  },
];

export const links = [
  {
    title: "Services",
    list: [
      { label: "Dedicated Teams", url: "https://example.com/dedicated-teams" },
      {
        label: "Software Staffing",
        url: "https://example.com/software-staffing",
      },
      {
        label: "Project Management",
        url: "https://example.com/project-management",
      },
    ],
  },
  {
    title: "Technology Expertise",
    list: [
      { label: "Application Development", url: "https://example.com/clients" },
      { label: "Mobile Development", url: "https://example.com/careers" },
    ],
  },
  {
    title: "About",
    list: [
      { label: "Clients", url: "https://example.com/clients" },
      { label: "Careers", url: "https://example.com/careers" },
      { label: "Diversity", url: "https://example.com/diversity" },
      { label: "About Us", url: "https://example.com/diversity" },
      { label: "Referal Program", url: "https://example.com/diversity" },
    ],
  },
  {
    title: "Resources",
    list: [
      { label: "Why WireFlow", url: "https://example.com/clients" },
      { label: "Blog", url: "https://example.com/careers" },
    ],
  },
];

export const footerSocials = [
  {
    link: "https://twitter.com/wireflowtech",
    icon: "fa-brands fa-twitter",
  },
  {
    link: "https://example.com/clients",
    icon: "fa-brands fa-linkedin",
  },
  {
    link: "https://example.com/clients",
    icon: "fa-brands fa-square-facebook",
  },
];

export const serviceOptions = [
  { value: "Website Development", label: "Website Development" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  {
    value: "Website & Mobile App Development",
    label: "Website & Mobile App Development",
  },
];

export const addonsOptions = [
  { value: "none", label: "None" },
  { value: "SEO Optimization", label: "SEO Optimization" },
  { value: "Maintenance & Support", label: "Maintenance & Support" },
  { value: "App UI/UX Design", label: "App UI/UX Design" },
  { value: "Digital Marketing", label: "Digital Marketing" },
];

export const FAQModal = [
  {
    question: "What is the process for web development projects?",
    answer:
      "Our web development process involves gathering requirements, designing the user interface, coding, testing, and deploying the website, ensuring a seamless and efficient development experience for our clients.",
    delay: 0.2,
    serviceType: "Web Development"
  },
  {
    question: "How long does it usually take to complete a web development project?",
    answer:
      "The timeline for a web development project depends on its complexity and features. We work closely with our clients to establish realistic timelines and milestones.",
    delay: 0.4,
    serviceType: "Web Development"
  },
  {
    question: "What are the key benefits of responsive web design?",
    answer:
      "Responsive web design ensures that your website looks and functions well on various devices, improving user experience and SEO rankings. It also reduces the need for separate mobile sites.",
    delay: 0.3,
    serviceType: "Web Development"
  },
  {
    question: "How does SEO optimization help improve website visibility?",
    answer:
      "SEO optimization enhances your website's visibility by implementing various strategies such as keyword research, on-page optimization, link building, and content optimization, ensuring higher search engine rankings and increased organic traffic.",
    delay: 0.4,
    serviceType: "Digital Marketing"
  },
  {
    question: "What social media platforms do you recommend for digital marketing?",
    answer:
      "The choice of social media platforms depends on your target audience and goals. We can help you identify the most suitable platforms for your business and create effective marketing campaigns.",
    delay: 0.3,
    serviceType: "Digital Marketing"
  },
  {
    question: "How can mobile applications benefit my business?",
    answer:
      "Mobile applications can enhance customer engagement, streamline operations, and provide a convenient way for users to interact with your brand. They also open up new revenue streams and opportunities for growth.",
    delay: 0.4,
    serviceType: "Mobile Application Development"
  },
  {
    question: "What platforms do you develop mobile applications for?",
    answer:
      "We develop mobile applications for iOS, Android, and cross-platform solutions, ensuring that your app reaches a wide audience and functions seamlessly across different devices.",
    delay: 0.3,
    serviceType: "Mobile Application Development"
  },
  {
    question: "How do you ensure the security of mobile applications?",
    answer:
      "We prioritize security throughout the development process, implementing encryption, secure authentication, and regular security audits to protect your users' data and ensure a safe mobile experience.",
    delay: 0.4,
    serviceType: "Mobile Application Development"
  },
];
