import { AiFillStar } from "react-icons/ai";

export const renderStars = (stars) => {
  const star = <AiFillStar />;

  const starsAmount = [];

  if (stars <= 5) {
    for (let i = 1; i <= stars; i++) {
      starsAmount.push(star);
    }
  } else {
    for (let i = 1; i <= 5; i++) {
      starsAmount.push(star);
    }
  }

  const reviewStars = starsAmount.map((star, index) => (
    <AiFillStar key={index} />
  ));

  return reviewStars;
};
