import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faLinkedin, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

library.add(faTwitter, faLinkedin, faSquareFacebook)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


