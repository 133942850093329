import dayjs from "dayjs";
import { useState } from "react";

export const useForm = (url, { defaultValues = {} }) => {
  const [formData, setFormData] = useState({ ...defaultValues });
  const [isLoading, setIsLoading] = useState(false);
  const [initialState] = useState({ ...defaultValues });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState();
  const [formSucess, setFormSucess] = useState(false);

  const setFormInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }; // Used for setting any input

  const setFormDate = (e, name) => {
    const formattedDate = dayjs(e).format("YYYY-MM-DD");
    setFormData({ ...formData, [name]: formattedDate });
  }; // Used for setting antd date

  const setFormTime = (e, name) => {
    const formattedTime = dayjs(e).format("h:mm A");
    setFormData({ ...formData, [name]: formattedTime });
  }; // Used for setting antd time

  const setFormOther = (e, name) => {
    setFormData({ ...formData, [name]: e });
  }; // Used for setting other component values

  const reset = () => {
    setFormData(initialState);
    setError();
  }; // reset function to restart form to the initial state

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }

      if (response.ok) {
        reset();
        setFormSucess(true);
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      }
    } catch (error) {
      console.error(error, "Error sending data - useFormHook");
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    setFormInput, // EX USE. setFormDate(e) // for any html tag that returns an event with a name & value.
    setFormDate, // EX USE. setFormDate(e, "date") // for date components for ui libraries that only return the value in the event.
    setFormTime, // EX USE. setFormTime(e, "time") // for time components for ui libraries that only return the value in the event.
    setFormOther, // EX USE. setFormOther(e, "other") // for any inputs that return just the value.
    handleOnSubmit, // used for the form onSubmit().
    reset, // used to reset the form back to the initial state.
    isLoading, // used to show if the form is in loading status.
    isSubmitted, // confirms if the form was submitted.
    formSucess, // confirms the form was successful.
    error, // provides any errors if not sucessful.
    formData, // returns current form data for setting input value (ex. value = {formData.name}).
  };
};
