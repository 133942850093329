import React from "react";
import Hero from "../Components/Hero";
import CompanyStrip from "../Components/CompanyStrip";
import ResultsDelivered from "../Components/ResultsDelivered";
import EverythingDone from "../Components/EverythingDone";
import AboutUs from "../Components/AboutUs";
import Delivering from "../Components/Delivering";
import Services from "../Components/Services";
import Form from "../Components/Form";
import Footer from "../Components/Footer";
import Addons from "../Components/Addons";
import FAQ from "../Components/FAQ";
import Testimonials from "../Components/Testimonials";


const homepage = () => {
  return (
    <div>
      <Hero />
      <CompanyStrip />
      <ResultsDelivered />
      {/* <EverythingDone /> */}
      {/* <AboutUs /> */}
      <Delivering />
      <Services />
      <Addons />
      <FAQ />
      <Testimonials />
      <Form />
      <Footer />
    </div>
  );
};

export default homepage;
