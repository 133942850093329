import React from "react";
import Button from "./Button";
import macbook from "../Assets/macbook-mockup.png";
import phone from "../Assets/phone-mockup.png";
import blob1 from "../Assets/Vector.svg";
import "./Services.css";
import BookCallModal from "./BookCallModal";
import { Link } from "react-scroll";
import Animate from "./Animations/Animate";
const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <div className="row">
          <div className="services-container">
            <Animate
              initialY="0%"
              initialX="-10%"
              InViewX="0"
              delay={0.3}
              className="service-text-container"
              id="/services"
            >
              <p className="service-title-p">DEVELOPMENT SERVICES</p>
              <h1 className="service-title-h1">
                CRAFTING <span className="lighter">INNOVATIVE</span> DIGITAL{" "}
                <span className="lighter">SOLUTIONS</span>
              </h1>
              <BookCallModal btn />
            </Animate>
            <div className="service-boxes">
              <Animate delay={0.2} className="service-box2">
                <h1 className="service-box-title-h1">
                  <span className="service-box-title-highlight-1">
                    WEBSITE <br />
                  </span>
                  DEVELOPMENT
                </h1>
                <p className="service-box-title-p">
                  Transforming ideas into captivating websites with expertise
                  and precision.
                </p>
                <ul className="service-box-list">
                  <li className="service-box-list-item">
                    Customized Solutions
                  </li>
                  <li className="service-box-list-item">
                    Cutting-Edge Technologies
                  </li>
                  <li className="service-box-list-item">
                    Performance Optimization
                  </li>
                  <li className="service-box-list-item">
                    Seamless Experiences
                  </li>
                  <li className="service-box-list-item">Engaging Designs</li>
                </ul>
                <div className="macbook-container">
                  <img className="macbook-mockup" src={macbook} alt="" />
                </div>
              </Animate>
              <div className="service-box-container-2">
                <Animate delay={0.1} className="service-box2">
                  <h1 className="service-box-title-h1">
                    <span className="service-box-title-highlight-2">
                      MOBILE APP <br />
                    </span>
                    DEVELOPMENT
                  </h1>
                  <p className="service-box-title-p">
                    Crafting engaging mobile experiences with versatile
                    functionality.
                  </p>
                  <ul className="service-box-list">
                    <li className="service-box-list-item">Intuitive UI/UX</li>
                    <li className="service-box-list-item">
                      Multi-Platform Support
                    </li>
                    <li className="service-box-list-item">
                      Feature-Rich Functionality
                    </li>
                    <li className="service-box-list-item">
                      Agile Development Approach
                    </li>
                    <li className="service-box-list-item">
                      Scalable App Solutions
                    </li>
                  </ul>
                  <div className="phone-container">
                    <img className="phone-mockup" src={phone} alt="" />
                  </div>
                </Animate>
                <Animate delay={0.3} className="service-box3">
                  <h1 className="service-box-title-h1">
                    <span className="service-box-title-highlight-3">
                      VALUABLE <br />
                    </span>
                    ADD-ONS
                  </h1>
                  <p className="service-box-title-p">
                    Unlock your digital potential with our range of empowering
                    services, designed to enhance your online presence and drive
                    lasting success.
                  </p>
                  <ul className="service-box-list">
                    <h3>
                      SEO <span className="bold">OPTIMIZATION</span>
                    </h3>
                    <h3>
                      <span className="bold">MAINTENANCE</span> &
                      <span className="bold"> SUPPORT</span>
                    </h3>
                    <h3>
                      APP <span className="bold">UI/UX DESIGN</span>
                    </h3>
                  </ul>
                  <div className="addon-btn">
                    <Link
                      to="/addon"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      <Button btn={true}>Learn More</Button>
                    </Link>
                  </div>
                </Animate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
